// extracted by mini-css-extract-plugin
export var accordionSection = "why-choose-juno-college-bootcamp-module--accordion-section--EIFmR";
export var allScores = "why-choose-juno-college-bootcamp-module--all-scores--BxWut";
export var asideContent = "why-choose-juno-college-bootcamp-module--aside-content--8tRt7";
export var careerSupport = "why-choose-juno-college-bootcamp-module--career-support--rjYXV";
export var column1 = "why-choose-juno-college-bootcamp-module--column1--2qzZ8";
export var column2 = "why-choose-juno-college-bootcamp-module--column2--ys93k";
export var column3 = "why-choose-juno-college-bootcamp-module--column3--9vFHI";
export var column4 = "why-choose-juno-college-bootcamp-module--column4--+85uR";
export var communitySection = "why-choose-juno-college-bootcamp-module--community-section--X-8aY";
export var comparisonTable = "why-choose-juno-college-bootcamp-module--comparison-table--eMd3f";
export var contentWrapper = "why-choose-juno-college-bootcamp-module--content-wrapper--voYiI";
export var csatScore = "why-choose-juno-college-bootcamp-module--csat-score--xF5TH";
export var ctaButtons = "why-choose-juno-college-bootcamp-module--cta-buttons--40CL4";
export var fadeIn = "why-choose-juno-college-bootcamp-module--fade-in--jCUNi";
export var fadeInNumbers = "why-choose-juno-college-bootcamp-module--fade-in-numbers--lS6EU";
export var fadeOut = "why-choose-juno-college-bootcamp-module--fade-out--RFAgs";
export var fadeOutNumbers = "why-choose-juno-college-bootcamp-module--fade-out-numbers--Lin0W";
export var gradSuccess = "why-choose-juno-college-bootcamp-module--grad-success--aAZ9Z";
export var headshot = "why-choose-juno-college-bootcamp-module--headshot--5c3FA";
export var hero = "why-choose-juno-college-bootcamp-module--hero--TC6z+";
export var instructor = "why-choose-juno-college-bootcamp-module--instructor--dfVWM";
export var instructorGrid = "why-choose-juno-college-bootcamp-module--instructor-grid--pPRfC";
export var instructorSection = "why-choose-juno-college-bootcamp-module--instructor-section--jQqfi";
export var junoLogo = "why-choose-juno-college-bootcamp-module--juno-logo--0Mp-u";
export var logoComponent = "why-choose-juno-college-bootcamp-module--logo-component--faAq8";
export var logos = "why-choose-juno-college-bootcamp-module--logos--z-gEG";
export var mobileCellHeading = "why-choose-juno-college-bootcamp-module--mobile-cell-heading--3l7aq";
export var percentage = "why-choose-juno-college-bootcamp-module--percentage--5MD27";
export var quoteCard = "why-choose-juno-college-bootcamp-module--quote-card--duNNp";
export var quoteWrapper = "why-choose-juno-college-bootcamp-module--quote-wrapper--MzV4W";
export var ratingGroup = "why-choose-juno-college-bootcamp-module--rating-group--IMBAU";
export var ratings = "why-choose-juno-college-bootcamp-module--ratings--b0317";
export var sectionDescription = "why-choose-juno-college-bootcamp-module--section-description--NawPX";
export var sectionHeading = "why-choose-juno-college-bootcamp-module--section-heading--IV2zh";
export var simpleBanner = "why-choose-juno-college-bootcamp-module--simple-banner--WPvZk";
export var source = "why-choose-juno-college-bootcamp-module--source--uET1w";