// extracted by mini-css-extract-plugin
export var fadeInStars = "FiveStars-module--fade-in-stars--uUb2U";
export var fadeOutStars = "FiveStars-module--fade-out-stars--ZhbRB";
export var interiorDots = "FiveStars-module--interior-dots--QLvaZ";
export var interiorStarBg = "FiveStars-module--interior-star-bg--P4tYT";
export var pageLoadDelay = "FiveStars-module--page-load-delay--DOFUo";
export var star1 = "FiveStars-module--star-1--a1v1i";
export var star2 = "FiveStars-module--star-2--elWh+";
export var star3 = "FiveStars-module--star-3--dKYLo";
export var star4 = "FiveStars-module--star-4--Gm17i";
export var star5 = "FiveStars-module--star-5--MYRnD";