// extracted by mini-css-extract-plugin
export var banner = "StudentSuccessBanner-module--banner--Ynbwn";
export var calendlyEmbed = "StudentSuccessBanner-module--calendly-embed--ghCWz";
export var description = "StudentSuccessBanner-module--description--qE6Wu";
export var fullWidth = "StudentSuccessBanner-module--full-width--HyWTt";
export var halfWidth = "StudentSuccessBanner-module--half-width--g1csj";
export var heading = "StudentSuccessBanner-module--heading--0pgzg";
export var person = "StudentSuccessBanner-module--person--UMI9s";
export var person1 = "StudentSuccessBanner-module--person1--F0g+N";
export var person2 = "StudentSuccessBanner-module--person2--z7R99";
export var person3 = "StudentSuccessBanner-module--person3--MVmDk";
export var person4 = "StudentSuccessBanner-module--person4--BTa6O";
export var person5 = "StudentSuccessBanner-module--person5--tQLLA";
export var portraits = "StudentSuccessBanner-module--portraits--Z4Jtt";
export var totalPortraits2 = "StudentSuccessBanner-module--total-portraits-2--iqFBf";
export var totalPortraits3 = "StudentSuccessBanner-module--total-portraits-3--BnkgC";
export var totalPortraits4 = "StudentSuccessBanner-module--total-portraits-4--B0s+u";
export var totalPortraits5 = "StudentSuccessBanner-module--total-portraits-5--YVvjD";
export var withCalendly = "StudentSuccessBanner-module--with-calendly--HGuvc";