// extracted by mini-css-extract-plugin
export var heading = "TestimonialStandardCard-module--heading--5cwhD";
export var headshot = "TestimonialStandardCard-module--headshot--B16rG";
export var jobAfterJuno = "TestimonialStandardCard-module--job-after-juno--czQUE";
export var jobBeforeJuno = "TestimonialStandardCard-module--job-before-juno--cz+qy";
export var noHeadshot = "TestimonialStandardCard-module--no-headshot--uSCRR";
export var person = "TestimonialStandardCard-module--person--IWeWM";
export var quote = "TestimonialStandardCard-module--quote--3Gms1";
export var simpleCard = "TestimonialStandardCard-module--simple-card--kv8iF";
export var standardCard = "TestimonialStandardCard-module--standard-card--SYJHQ";
export var textContainer = "TestimonialStandardCard-module--text-container--yAa9e";